<template>
  <div>
    <div
      class="
        part
        border-radius-6
        box-shadow-light-grey
        padding-20
        height-calc-type1
      "
    >
      <div class="flex-between operation padding-bottom-20">
        <div>
          <el-button
            type="succes"
            class="new-book"
            @click="handlePageChange(1)"
            style="margin-left: 10px"
          >
            刷新
          </el-button>
          <el-button
            class="margin-right-ten"
            type="primary"
            @click="handleEdit()"
            >新增素材</el-button
          >
          <el-select
            class="margin-right-ten"
            style="width: 140px"
            v-model="params.platformId"
            value-key="id"
            filterable
            placeholder="平台"
            :clearable="true"
          >
            <el-option
              v-for="item in messagePlatformListDetail"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            >
            </el-option>
          </el-select>
          <el-date-picker
            class="margin-right-ten"
            v-model="params.selectTime"
            style="width: 260px"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="-"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
          <el-input
            class="margin-right-ten"
            style="width: 140px"
            v-model="params.bookName"
            placeholder="输入书籍名称"
          ></el-input>
          <el-input
            class="margin-right-ten"
            style="width: 140px"
            v-model="params.remark"
            placeholder="输入备注"
          ></el-input>
          <el-button
            type="primary"
            @click="handlePageChange(1)"
            style="margin-left: 10px"
          >
            搜索
          </el-button>
          <el-button type="info" @click="handleReset"> 重置 </el-button>
        </div>
      </div>
      <el-table
        class="table-service"
        v-loading="loading"
        :data="tableData"
        height="calc(100vh - 310px)"
        style="width: 100%"
      >
        <el-table-column prop="id" label="id"> </el-table-column>
        <el-table-column prop="platformStr" label="平台"> </el-table-column>
        <el-table-column prop="bookName" width="200px" label="书籍名">
        </el-table-column>
        <el-table-column prop="endIdx" label="截止章节"> </el-table-column>
        <el-table-column prop="relation.digest" width="350px" label="摘要">
          <template slot-scope="scope">
            <el-popover placement="top" title="摘要" trigger="hover">
              <div>
                {{ scope.row.relation.digest }}
              </div>
              <div class="line-overflow" slot="reference">
                {{ scope.row.relation.digest }}
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="remark" width="300px" label="备注">
        </el-table-column>
        <el-table-column prop="needOpenComment" width="80px" label="可评论">
          <template slot-scope="scope">
            {{ scope.row.needOpenComment ? '是' : '否' }}
          </template>
        </el-table-column>
        <el-table-column
          prop="onlyFansCanComment"
          width="90px"
          label="仅粉丝评论"
        >
          <template slot-scope="scope">
            {{ scope.row.onlyFansCanComment ? '是' : '否' }}
          </template>
        </el-table-column>
        <el-table-column prop="createdTime" label="上传时间" width="160">
        </el-table-column>
        <el-table-column prop="action" width="180" label="操作">
          <template slot-scope="scope">
            <el-popover
              popper-class="phonePop"
              placement="left"
              width="400"
              trigger="click"
            >
              <div class="device-ios">
                <div class="device-inner" id="deviceInner">
                  <div v-html="scope.row.relation.content"></div>
                </div>
              </div>
              <el-button
                type="info"
                icon="el-icon-document"
                circle
                slot="reference"
                style="margin-right: 5px"
              ></el-button>
            </el-popover>

            <el-tooltip
              class="item"
              effect="dark"
              content="编辑"
              placement="top-start"
            >
              <el-button
                type="primary"
                icon="el-icon-edit"
                circle
                @click="handleEdit(scope.row)"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="删除"
              placement="top-start"
            >
              <el-button
                type="danger"
                icon="el-icon-delete"
                circle
                @click="handleDelete(scope.row)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 10px; text-align: right"
        background
        @current-change="handlePageChange"
        :current-page="page"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 编辑新增dialog -->
    <el-dialog
      :title="isEdit ? '编辑素材' : '新增素材'"
      :visible.sync="showEdit"
      :fullscreen="true"
      :destroy-on-close="true"
      center
      class="editResource"
      @close="handleCancle"
      v-loading="loadingEditor"
    >
      <el-row :gutter="20">
        <el-form
          label-position="left"
          label-width="120px"
          class="form-container"
        >
          <!-- 表单顶部 -->
          <!-- 表单中部 -->
          <div class="form-body">
            <div class="form-body-left">
              <el-form-item label="平台：" class="necessary">
                <el-select
                  style="width: 100%"
                  v-model="sendForm.selectChannel"
                  value-key="id"
                  filterable
                  placeholder="公众号"
                  :disabled="isEdit"
                  @change="handleEditChannelChange"
                >
                  <el-option
                    v-for="item in messagePlatformListDetail"
                    :key="item.id"
                    :value="item.id"
                    :label="item.name"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="备注：">
                <el-input
                  v-model="sendForm.remark"
                  placeholder="请填写备注消息"
                ></el-input>
              </el-form-item>
              <el-form-item
                class="necessary"
                label="书籍："
                style="margin-bottom: 14px"
              >
                <div class="select-book">
                  <p>
                    <span v-if="bookDetail"
                      >{{ bookDetail.book.bookName }}&nbsp;/&nbsp;截止第{{
                        bookDetail.chapter.endIdx
                      }}章</span
                    >
                    <span v-else>请选择书籍</span>
                  </p>
                  <template v-if="bookDetail">
                    <!-- 针对阳光书城 、忧书阁 、七悦-->
                    <el-popover
                      placement="top"
                      v-if="
                        sendForm.selectChannel === 3 ||
                        sendForm.selectChannel === 4 ||
                        sendForm.selectChannel === 6
                      "
                      trigger="hover"
                    >
                      <div class="button-list">
                        <el-button type="primary" @click="getResourceType(1)"
                          >文案</el-button
                        >
                        <el-button type="warning" @click="getResourceType(2)"
                          >图片</el-button
                        >
                        <el-button type="danger" @click="getResourceType(3)"
                          >背景图</el-button
                        >
                      </div>
                      <el-button slot="reference">图文详情</el-button>
                    </el-popover>
                    <el-button v-else @click="handleIframe">图文详情</el-button>
                    <i
                      class="el-icon-question"
                      v-if="bookDetail"
                      title='---首次点击“图文详情”时可能会新打开对应平台的登录页面的窗口，登录之后最好关闭窗口在进行相应的操作。
---如果点击“图文详情”之后弹出的页面长时间未加载或者显示的是登录页面(海读书城)，可以尝试"刷新页面"！'
                    ></i>
                  </template>
                  <el-button
                    type="primary"
                    @click="handleBookShow"
                    style="margin-right: 10px"
                    >选择书籍</el-button
                  >
                </div>
              </el-form-item>
              <el-form-item label="作者：" style="margin-bottom: 14px">
                <el-input
                  v-model="sendForm.author"
                  placeholder="输入作者"
                  size="normal"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item
                label="图文摘要："
                class="necessary"
                style="margin-bottom: 14px"
              >
                <el-input
                  type="textarea"
                  :rows="2"
                  v-model="sendForm.textDigest"
                  placeholder="请输入图文信息摘要"
                  :maxlength="120"
                >
                </el-input>
              </el-form-item>
              <el-form-item label="是否打开评论：" style="margin-bottom: 14px">
                <el-radio-group v-model="sendForm.needOpenComment">
                  <el-radio :label="0">否</el-radio>
                  <el-radio :label="1">是</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="仅粉丝评论：" style="margin-bottom: 14px">
                <el-radio-group v-model="sendForm.onlyFansCanComment">
                  <el-radio :label="0">否</el-radio>
                  <el-radio :label="1">是</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
            <div class="form-body-right">
              <el-button
                class="insert-button"
                type="primary"
                size="mini"
                @click="handleInsertImage"
                >插入原文底部图</el-button
              >
              <div id="wangEditor" style="height: 830px"></div>
              <back-up-down :target="editorBox"></back-up-down>
            </div>
          </div>
        </el-form>
      </el-row>
      <span slot="footer">
        <el-button @click="handleCancle">取消</el-button>
        <el-button type="primary" @click="handleSubmit" :loading="editorButton"
          >确定</el-button
        >
      </span>
      <!-- 书籍选择dialog -->
      <chooseBook
        :showBook="showBook"
        @close="showBook = false"
        @success="bookChooseSuccess"
        :channel="selectChannel"
      />
    </el-dialog>
    <template v-if="bookDetail">
      <el-dialog
        :title="`${
          this.sendForm.selectChannel !== undefined &&
          this.sendForm.selectChannel !== null
            ? messagePlatformListDetail.find(
                (item) => item.id === this.sendForm.selectChannel
              ).name
            : ''
        }  ${bookDetail.book.bookName}--截止到第${bookDetail.chapter.endIdx}章`"
        width="60vw"
        top="2vh"
        class="iframe-dialog"
        v-dialogDrag
        :visible.sync="showIframe"
        :before-close="handleIframeClose"
      >
        <div class="refresh" style="margin-bottom: 10px">
          <el-button @click="refreshIframePage">刷新页面</el-button>
        </div>
        <div class="loading-div" v-if="iframeLoading">
          内容加载中，请稍后...
        </div>
        <iframe
          :src="bookSrc"
          name="ifrmname"
          ref="iframe"
          class="iframe-resource"
          frameborder="0"
        ></iframe>
      </el-dialog>
    </template>
    <account-list
      :show="showAccountList"
      :defaultPlatformId="sendForm.selectChannel"
      :canDisabled="true"
      :defaultChannelName="
        (() => (testChannelInfo ? testChannelInfo.channelName : ''))()
      "
      @close="
        () => {
          this.showAccountList = false
        }
      "
    >
    </account-list>
    <div class="mask" v-if="showLoadingMask" v-loading="showLoadingMask"></div>
    <el-dialog title="验证码" :visible.sync="showAuthCode" width="360px">
      <div class="login-box" v-if="showAuthCode">
        <div class="code-img-box" v-loading="loadingAuthCode">
          <el-card
            title="提交登录验证码"
            style="width: 100%"
            v-if="showCodeImg"
          >
            <img :src="codeURL" alt="" />
            <el-input placeholder="请输入验证码" v-model="validCode"></el-input>
            <el-button style="margin-top: 20px" @click="handleCancel"
              >取消</el-button
            >
            <el-button
              type="primary"
              style="margin-top: 20px"
              :disabled="!validCode"
              @click="handleValidCodeSubmit"
              >提交</el-button
            >
          </el-card>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import backUpDown from '@/components/upDown'
import replaceAsync from 'string-replace-async'
import {
  customFile,
  customFileGetToken,
  convertBase64UrlToBlob,
  uploadFile,
  uploadFilebyToken,
} from '@/api/common'
import { compressUpload } from '@/api/novelPut'
import { getLoginAccount } from '@/api/account'
import Editor from 'wangeditor'
import chooseBook from './chooseBook'
import AccountList from '@/layout/account'
import {
  getPlatformTagSource,
  addPlatformTagSource,
  changePlatformTagSource,
  deletePlatformTagSource,
  getAccountStatus,
  getBookChapter,
} from '@/api/service'
import {
  messagePlatformListDetail,
  pickerOptions,
  hostArr,
} from '@/assets/js/options'
export default {
  props: ['accountChannelId', 'relationId', 'channelList'],
  data() {
    return {
      wangEditor: null,
      showEdit: false,
      showBook: false,
      isEdit: false,
      loading: false,
      status: null,
      tableData: [],
      page: 1,
      pageSize: 15,
      total: 0,
      params: {},
      sendForm: {
        needOpenComment: 0,
        onlyFansCanComment: 0,
      },
      bookDetail: null,
      selectChannel: null,
      messagePlatformListDetail,
      pickerOptions,
      editorBox: null,
      editorButton: false,
      showIframe: false,
      // extendId: 'figgdochegaejmdigdncnidhdpdeaaog', // 线上插件ID
      // extendId: "gdpdodfafaiacgmnmchoikalikiemakp", // 线下插件Id
      extendId: process.env.VUE_APP_BASE_extendId, // 'devT'
      bookSrc: '',
      resourceType: null,
      loadingMask: false,
      testChannelInfo: null, // 平台的登录公众号信息(用户点击"图文详情"判断时使用)
      showAccountList: false,
      iframeLoading: false,
      isIframeLoadComplete: false, // iframe是否加载完成 与 iframeLoaidng进行区分
      host: null, // 平台host相关信息
      showAuthCode: false,
      loadingAuthCode: false,
      showCodeImg: false, // 是否显示验证码图片
      validCode: null, // 验证码
      codeURL: '',
      codeCookie: '',
      access_token: '', // 花生书城token
      showLoadingMask: '',
      loadingEditor: false,
    }
  },
  components: {
    chooseBook,
    backUpDown,
    AccountList,
  },
  computed: {},
  watch: {
    showEdit: function (newvalue) {
      if (newvalue) {
        // 初始化公众号
        const defaultAccount = this.channelList.find(
          (item) => item.id === this.accountChannelId
        )
        if (this.isEdit) {
          const tmpSelectChanel = this.channelList.find((element) => {
            return element.platform === this.sendForm.platformId
          })
          this.selectChannel = {
            id: tmpSelectChanel.id,
            channelName: this.sendForm.platformStr,
            platform: this.sendForm.platformId,
          }
        } else {
          this.$set(this.sendForm, 'needOpenComment', 0)
          this.$set(this.sendForm, 'onlyFansCanComment', 0)
          this.selectChannel = {
            id: this.accountChannelId,
            channelName: defaultAccount ? defaultAccount.channelName : '',
            platform: defaultAccount ? defaultAccount.platform : null,
          }
        }
        this.$set(this.sendForm, 'selectChannel', this.selectChannel.platform)
        // 初始化富文本
        this.loadingEditor = true
        this.$nextTick(() => {
          this.loadingEditor = false
          if (!this.wangEditor) {
            this.wangEditor = new Editor('#wangEditor')
            this.wangEditor.config.zIndex = 1999
            this.wangEditor.config.pasteFilterStyle = false
            this.wangEditor.config.pasteTextHandle = function (pasteStr) {
              // var reg = /(<img[^>]*)(\/{1}>)/gi;
              pasteStr = pasteStr
                .replace(/(<img[^>]*)(\/{1}>)/gi, "$1 width='100%' $2")
                .replace(/(<div\s{0,}(.*?)>)/gim, '<p>')
                .replace(/<\s{0,}\/div>/gim, '</p>')
              return pasteStr
              // that.wangEditor.txt.html(pasteStr);
            }
            this.wangEditor.config.height = 740
            this.wangEditor.config.uploadImgMaxLength = 1
            this.wangEditor.config.menus = [
              'head',
              'bold',
              'fontSize',
              'fontName',
              'italic',
              'underline',
              'strikeThrough',
              'indent',
              'lineHeight',
              'foreColor',
              'backColor',
              'link',
              'quote',
              'image',
              'table',
              'code',
              'splitLine',
              'undo',
              'redo',
              'list',
              'todo',
              'justify',
            ]
            this.wangEditor.create()
            // 上传图片
            this.wangEditor.config.customUploadImg = (
              resultFiles,
              insertImgFn
            ) => {
              compressUpload({ file: resultFiles[0] })
                .then((res) => {
                  insertImgFn(res)
                })
                .catch((err) => {})
            }
            if (this.isEdit) {
              this.wangEditor.txt.html(this.sendForm.textContent)
            }
            // 获取wangeditor dom
            if (document.getElementsByClassName('w-e-text')) {
              this.editorBox = document.getElementsByClassName('w-e-text')[0]
            }
          }
        })
      } else {
        if (this.isInstallChromeExtension()) {
          chrome.runtime.sendMessage(this.extendId, {
            novelapi: 'resetOperationFlag',
          })
        }
      }
    },
    'sendForm.selectChannel': {
      handler(newV) {
        if (newV !== undefined) {
          this.handlePlatformChange(newV)
          this.bookSrc = ''
          this.isIframeLoadComplete = false
        }
      },
    },
    bookSrc: {
      handler() {
        this.isIframeLoadComplete = false
      },
    },
  },
  mounted() {
    window.onbeforeunload = () => {
      if (this.isInstallChromeExtension()) {
        chrome.runtime.sendMessage(this.extendId, {
          novelapi: 'resetOperationFlag',
        })
      }
    }
  },
  methods: {
    // 删除素材
    handleDelete(item) {
      this.$confirm('确定要删除素材?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          deletePlatformTagSource(item.id).then((res) => {
            this.$message.success('删除成功')
            this.handleReset()
          })
        })
        .catch(() => {})
    },
    handleCancle() {
      this.wangEditor = null
      this.bookDetail = null
      this.showEdit = false
      this.sendForm = {}
    },
    bookChooseSuccess(value) {
      this.bookDetail = value
    },
    handleEditChannelChange(item) {
      const tmpSelectChanel = this.channelList.find((element) => {
        return element.platform === item
      })
      this.selectChannel = {
        id: tmpSelectChanel.id,
        channelName: tmpSelectChanel.platformStr,
        platform: tmpSelectChanel.platform,
      }
      this.bookDetail = null
    },
    handleReset() {
      this.params = {}
      this.page = 1
      this.handlePageChange()
    },
    // 插入底部图
    handleInsertImage() {
      this.wangEditor.cmd.do(
        'insertHTML',
        '<img src="https://file.gesmen.com.cn//novel/bottomPic.png" width=100%;>'
      )
    },
    handleBookShow() {
      this.showBook = true
    },
    handlePageChange(page) {
      this.page = page || 1
      if (this.params.selectTime && this.params.selectTime.length > 0) {
        this.params.startDate = this.params.selectTime[0]
        this.params.endDate = this.params.selectTime[1]
      } else {
        this.params.startDate = null
        this.params.endDate = null
      }
      getPlatformTagSource({
        page: this.page,
        pageSize: this.pageSize,
        ...this.params,
      }).then((res) => {
        this.tableData = res.list
        this.total = res.total
      })
    },
    handleEdit(value) {
      // 编辑
      if (value) {
        this.bookDetail = {
          book: {
            bookId: value.bookId,
            bookName: value.bookName,
          },
          chapter: {
            bookArticleId: value.bookArticleId,
            bookArticleIdx: value.bookArticleIdx,
            endIdx: value.endIdx,
            curChapterId: value.bookArticleId,
          },
        }
        this.sendForm = {
          id: value.id,
          author: value.author,
          needOpenComment: value.needOpenComment,
          onlyFansCanComment: value.onlyFansCanComment,
          remark: value.remark,
          platformId: value.platformId,
          textContent: value.relation.content,
          textDigest: value.relation.digest,
        }
        this.isEdit = true
        // 新增
      } else {
        this.isEdit = false
      }
      this.showEdit = true
    },
    handleTestT() {
      this.wangEditor.cmd.do(
        'insertimage',
        'https://static-legacy.dingtalk.com/media/lADPDh0cNjOI6FvNAo_NAig_552_655.jpg'
      )
    },
    // 新增编辑提交
    async handleSubmit() {
      this.sendForm.textContent = this.wangEditor.txt
        .html()
        .replace(/<p><\/p>/gim, '')
      if (!(this.sendForm.selectChannel >= 0)) {
        this.$message.error('请选择公众号')
        return
      }
      if (!this.bookDetail || !this.bookDetail.book) {
        this.$message.error('请选择书籍')
        return
      }
      if (!this.sendForm.textContent) {
        this.$message.error('请输入图文')
        return
      }
      if (!this.sendForm.textDigest) {
        this.$message.error('请输入图文摘要')
        return
      }
      this.editorButton = true
      // 如果是海读平台 需要将base64上传七牛云
      if (this.sendForm.selectChannel === 8) {
        // 获取七牛云token
        var token = await customFileGetToken()
        if (!token) {
          return this.$message.error('上传七牛云token失败')
        }
        var reg1 =
          /(<img src=)\"(data:image\/[png|jpg|jpeg]+;base64,(.*?))\"(.*?)(\/>)/gim
        var pasteStr = this.wangEditor.txt.html().replace(/<p><\/p>/gim, '')
        pasteStr = await replaceAsync(
          pasteStr,
          reg1,
          async (v, p1, p2, p3, p4, p5) => {
            // let res = await customFile(token, p3);
            // 转换base64为文件格式调用七牛云上传替换
            const targetFile = convertBase64UrlToBlob(p3)
            const res = await uploadFilebyToken(targetFile, token)
            return `${p1} ${res} ${p4}  ${p5}`
          }
        )
        this.sendForm.textContent = pasteStr
      }
      // let size = new Blob([this.sendForm.textContent]).size;
      // console.log("size:", size, "length:", this.sendForm.textContent.length);
      // if (size >= 1048576 || this.sendForm.textContent.length >= 20000) {
      //   this.$message.error(
      //     "文本内容过大,请精简处理(内容字节不可大于1M,字符数不可大于2万)"
      //   );
      //   return;
      // }
      // 新增
      if (!this.isEdit) {
        addPlatformTagSource({
          platformId: this.sendForm.selectChannel,
          bookId:
            this.bookDetail.chapter.sourceUuid || this.bookDetail.book.bookId,
          bookName: this.bookDetail.book.bookName,
          bookArticleId: this.bookDetail.chapter.bookArticleId,
          bookArticleIdx: this.bookDetail.chapter.bookArticleIdx || null,
          endIdx: this.bookDetail.chapter.endIdx,
          author: this.sendForm.author,
          needOpenComment: this.sendForm.needOpenComment,
          onlyFansCanComment: this.sendForm.onlyFansCanComment,
          remark: this.sendForm.remark,
          relation: {
            content: this.sendForm.textContent,
            digest: this.sendForm.textDigest,
          },
        })
          .then((res) => {
            this.bookDetail = null
            this.sendForm = {}
            this.$message.success('新增成功')
            this.wangEditor.txt.html('')
            this.wangEditor = null
            this.showEdit = false
            this.handlePageChange()
          })
          .finally(() => {
            this.editorButton = false
          })
        // 编辑
      } else {
        changePlatformTagSource(this.sendForm.id, {
          platformId: this.sendForm.selectChannel,
          bookId:
            this.bookDetail.chapter.sourceUuid || this.bookDetail.book.bookId,
          bookName: this.bookDetail.book.bookName,
          bookArticleId: this.bookDetail.chapter.bookArticleId,
          bookArticleIdx: this.bookDetail.chapter.bookArticleIdx || null,
          endIdx: this.bookDetail.chapter.endIdx,
          author: this.sendForm.author,
          needOpenComment: this.sendForm.needOpenComment,
          onlyFansCanComment: this.sendForm.onlyFansCanComment,
          remark: this.sendForm.remark,
          relation: {
            content: this.sendForm.textContent,
            digest: this.sendForm.textDigest,
          },
        })
          .then((res) => {
            this.bookDetail = null
            this.sendForm = {}
            this.$message.success('修改成功')
            this.wangEditor.txt.html('')
            this.wangEditor = null
            this.showEdit = false
            this.handlePageChange()
          })
          .finally(() => {
            this.editorButton = false
          })
      }
    },
    handleIframe() {
      // !判断是否安装了浏览器插件
      if (!this.isInstallChromeExtension()) {
        this.$message({
          showClose: true,
          message: '请安装浏览器插件!',
          type: 'warning',
        })
        return
      }
      if (!this.testChannelInfo) {
        this.$message({
          showClose: true,
          message: '该平台分类下暂无相关公众号!',
          type: 'warning',
        })
        return
      }
      getAccountStatus(this.testChannelInfo.id).then(async (res) => {
        // 登录
        if (res.isLogin) {
          getBookChapter(this.selectChannel.id, {
            cbid: this.bookDetail.book.bookId,
          }).then((res1) => {
            console.log(res1);
            let currentMap = []
            res1.forEach((item) => {
              if (item.free === 1) {
                currentMap.push(item)
              }
            })

            chrome.runtime.sendMessage(
              this.extendId,
              {
                novelapi: 'setOperationFlag',
                flag: true,
              },
              ({ code }) => {
                if (code === 200) {
                  chrome.runtime.sendMessage(
                    this.extendId,
                    {
                      platformResource: 'setYueWenStorage',
                      currentArray: currentMap,
                    },
                    ({ code, res }) => {}
                  )

                  chrome.runtime.sendMessage(
                    this.extendId,
                    {
                      platformResource: 'platformResource',
                      platformResourceData: res,
                      platformId: this.sendForm.selectChannel,
                      operateFlag: true,
                    },
                    ({ code, res }) => {
                      if (code === 200) {
                        const { url, host } = this.getBookSrc()
                        this.host = host
                        this.bookSrc = url
                        // console.log();
                        this.$nextTick(() => {
                          this.iframeLoading = !this.isIframeLoadComplete

                          chrome.runtime.sendMessage(
                            this.extendId,
                            {
                              novelapi: 'closeSameDomainTabs',
                              hostInfo: {
                                host: this.host,
                                platform: this.sendForm.selectChannel,
                              },
                            },
                            ({ code }) => {
                              if (code === 200) {
                                this.showIframe = true
                                this.checkIframeLoadingStats()
                              }
                            }
                          )
                        })
                      }
                    }
                  )
                }
              }
            )
          })
          // 未登录
        } else {
          this.handleLogin()
        }
      })
    },
    handleLogin() {
      if (!this.isInstallChromeExtension()) {
        this.$message({
          showClose: true,
          message: '请安装浏览器插件!',
          type: 'warning',
        })
        return
      }
      chrome.runtime.sendMessage(this.extendId, {
        novelapi: 'resetOperationFlag',
      })
      // * 阅文
      if (this.sendForm.selectChannel === 1) {
        chrome.runtime.sendMessage(
          this.extendId,
          {
            novelapi: 'clearCookie',
            url: 'https://open.yuewen.com',
          },
          (resString) => {
            const { code } = JSON.parse(resString)
            if (code === 200) {
              this.showAuthCode = false
              window.open(
                `https://open.yuewen.com/new/login?id=${this.testChannelInfo.id}`
              )
            }
          }
        )
      }
      // * 花生书城
      if (this.sendForm.selectChannel === 7) {
        // 花生书城
        localStorage.removeItem('cpsad')
        window.open(`https://ad.rlcps.cn/#/?id=${this.testChannelInfo.id}`)
      }
      //* 追书云、悠书阁、花生书城
      else if ([2, 4].includes(this.sendForm.selectChannel)) {
        this.showLoadingMask = true
        // 通过web端发送获取验证码的信息给浏览器插件的后台脚本(background.js)
        chrome.runtime.sendMessage(
          this.extendId,
          {
            novelapi: 'getValidCode',
            id: this.testChannelInfo.id,
          },
          (resString) => {
            const { code, res } = JSON.parse(resString)
            this.loadingAuthCode = false
            if (code === 200) {
              this.showLoadingMask = false
              this.showCodeImg = true
              this.showAuthCode = true
              if (this.sendForm.selectChannel === 7) {
                this.codeURL = res.data.img
                this.codeCookie = res.data.key
              } else {
                this.codeURL = res.base64Code
                this.codeCookie = res.cookies
              }
            } else {
              this.$message({
                showClose: true,
                message: '获取验证码出错!',
                type: 'error',
              })
              this.showLoadingMask = false
            }
          }
        )
      } else {
        chrome.runtime.sendMessage(
          this.extendId,
          {
            novelapi: 'getCookieById',
            data: {
              id: this.testChannelInfo.id,
              host: hostArr[this.sendForm.selectChannel].host,
              type: 0,
            },
          },
          (resString) => {
            this.loadingAuthCode = false
            // * 海读书城
            if (this.sendForm.selectChannel === 8) {
              chrome.runtime.sendMessage(
                this.extendId,
                {
                  novelapi: 'setHaiduLocalStorage',
                  data: {
                    SET_ROLES: [`${resString.data.data.userType}`],
                    token: resString.data.data.token,
                    userName: resString.data.data.list[0].userName,
                  },
                },
                () => {
                  this.showAuthCode = false
                  if (resString.code === 200) {
                    window.open(hostArr[this.sendForm.selectChannel].url)
                  } else {
                    this.$message({
                      showClose: true,
                      message: '获取海读相关数据失败，请稍后再试!',
                      type: 'error',
                    })
                  }
                }
              )
            } else {
              const { code, res } = JSON.parse(resString)
              this.showAuthCode = false
              if (code === 200) {
                window.open(hostArr[this.sendForm.selectChannel].url)
              } else {
                this.$message({
                  showClose: true,
                  message: '获取相关平台数据失败，请稍后再试!',
                  type: 'error',
                })
              }
            }
          }
        )
      }
    },
    handleCancel() {
      this.showAuthCode = false
      this.validCode = ''
      this.loadingAuthCode = false
      this.showCodeImg = false
      this.showLoadingMask = false
    },
    // 提交验证码
    handleValidCodeSubmit() {
      const { codeCookie, validCode, access_token, testChannelInfo } = this
      if (validCode) {
        this.loadingAuthCode = true
        chrome.runtime.sendMessage(
          this.extendId,
          {
            novelapi: 'getCookieByCode',
            data: {
              accountId: testChannelInfo.id,
              hostUri:
                this.sendForm.selectChannel !== 7
                  ? hostArr[this.sendForm.selectChannel].host
                  : 'https://ad.rlcps.cn/#/',
              params: {
                cookies: codeCookie,
                code: validCode,
              },
            },
          },
          (resString) => {
            const { code, res } = JSON.parse(resString)
            this.handleCancel()
            if (code === 200) {
              if (this.sendForm.selectChannel === 7) {
                chrome.runtime.sendMessage(this.extendId, {
                  novelapi: 'setAccessToken',
                  data: {
                    access_token: res[0].name,
                  },
                })
              }

              window.open(
                this.sendForm.selectChannel !== 7
                  ? hostArr[this.sendForm.selectChannel].url
                  : 'https://ad.rlcps.cn/#/'
              )
            } else {
              this.$message({
                showClose: true,
                message: '验证码输入错误，请重试!',
                type: 'error',
              })
            }
          }
        )
      }
    },
    getBookSrc() {
      const {
        chapter: { bookArticleId, endIdx },
        book: { bookId, bookName },
      } = this.bookDetail
      let targetValue = ''
      let curChapterId = ''
      if ([0, 1, 4, 5, 12].includes(this.sendForm.selectChannel)) {
        curChapterId = this.bookDetail.chapter.curChapterId
      }
      // 阳光 || 七悦
      if (
        this.sendForm.selectChannel === 3 ||
        this.sendForm.selectChannel === 6
      ) {
        if (this.resourceType === 1) {
          targetValue = 'essay'
        }
        if (this.resourceType === 2) {
          targetValue = 'essayimage'
        }
        if (this.resourceType === 3) {
          targetValue = 'background'
        }
      }
      // 忧书阁
      if (this.sendForm.selectChannel === 4) {
        if (this.resourceType === 1) targetValue = 'word_show'
        if (this.resourceType === 2) targetValue = 'img_oneslef'
        if (this.resourceType === 3) targetValue = 'img_oneself_show_bgd'
      }

      const platformList = [
        'zhangzhongyun',
        'yuewen',
        'zhuishuyun',
        'yangguang',
        'youshuge',
        'wangyi',
        'qiyue',
        'huasheng',
        'haidu',
        'xufeng',
        'zhangdu',
        'yangkuangK',
        'guofeng',
        'kanshuwnag',
      ]
      const platformSrc = {
        zhangzhongyun: {
          url: `https://inovel.818tu.com/backend/wx_article_editor?aid=${Number(
            curChapterId
          )}&mode=text`,
          host: 'https://inovel.818tu.com',
        },
        yuewen: {
          url: `https://open.yuewen.com/new/imgPromotion?cbid=${bookId}&ccbid=${Number(
            curChapterId
          )}&id=${endIdx}&book=${bookName}`,
          host: 'https://open.yuewen.com',
        },
        zhuishuyun: {
          url: '暂无',
          host: '',
        },
        yangguang: {
          url: `https://admin.kydba.cn/admin/referral.referral/${targetValue}/book_id/${bookId}/chapter_idx/${endIdx}`,
          host: 'https://admin.kydba.cn',
        },
        youshuge: {
          url: `https://novel.qinronread.com/article/${targetValue}/${bookId}/${
            this.resourceType !== 1 ? Number(curChapterId) : ''
          }`,
          host: 'https://novel.qinronread.com/',
        },
        // ! 网易文鼎
        wangyi: {
          url: `https://bi.reading.163.com/spa/book/promotion/${bookId}/${curChapterId}`,
          host: 'https://bi.reading.163.com',
        },
        qiyue: {
          url: `https://zhangwenwenhua.com/admin/referral.referral/${targetValue}/book_id/${bookId}/chapter_idx/${endIdx}`,
          host: 'https://zhangwenwenhua.com',
        },
        huasheng: {
          url: 'https://ad.rlcps.cn/#/works/fiction',
          host: 'https://ad.rlcps.cn',
        },
        haidu: {
          url: 'http://managers.haidubooks.com/defaultSummary/summaryInfo',
          host: 'https://ad.rlcps.cn',
        },
        xufeng: {
          url: null,
          host: null,
        },
        zhangdu: {
          url: null,
          host: null,
        },
        // 阳光快应用
        yangkuangK: {
          url: null,
          host: null,
        },
        // 国风
        guofeng: {
          url: `https://bi.reading.163.com/spa/book/promotion/${bookId}/${curChapterId}`,
          host: 'https://bi.reading.163.com',
        },
      }
      return platformSrc[`${platformList[this.sendForm.selectChannel]}`]
    },
    getResourceType(type) {
      this.resourceType = type
      this.handleIframe()
    },
    checkIframeLoadingStats() {
      this.$nextTick(() => {
        const iframe = document.querySelector('.iframe-resource')
        iframe.onload = () => {
          this.iframeLoading = false
          this.isIframeLoadComplete = true
        }
      })
    },
    handleIframeClose() {
      this.resourceType = null
      this.showIframe = false
    },
    handlePlatformChange(newV) {
      getLoginAccount({
        page: 1,
        pageSize: 15,
        platform: newV,
      }).then((res) => {
        if (res.list.length === 0) {
          this.testChannelInfo = null
        } else {
          // !默认取每个平台的"第一个"公众号的信息
          this.testChannelInfo = res.list[0]
        }
      })
    },
    // 刷新iframe
    refreshIframePage() {
      const iframe = document.querySelector('.iframe-resource')
      if (iframe) {
        window.open(iframe.src, 'ifrmname', '')
      }
    },
    // 判断是否安装了对应的浏览器插件
    isInstallChromeExtension() {
      return typeof chrome && !!chrome.runtime
    },
  },
  created() {
    this.handlePageChange()
  },
}
</script>

<style lang="scss" scoped>
.operation {
  background: #fff;
  // padding: 0 15px;
  // box-shadow: -2px -2px 10px rgba(180, 180, 180, 0.1);
}
.part {
  box-sizing: border-box;
  background: #fff;
  // box-shadow: -2px -2px 10px rgba(180, 180, 180, 0.1);
}

/**
*( (  \.---./  ) )
* '.__/o   o\__.'
* 新增编辑相关     by devT
**/
.form-container {
  width: 1620px;
  margin: auto;
}
.form-top {
  padding: 0 20px;
}
.form-body {
  background-color: #fbfbfb;
  margin-top: 10px;
  padding: 0 20px;
  padding-bottom: 0;
  display: flex;
  .message-box {
    justify-content: flex-start;
  }
  .form-body-left {
    padding: 0 10px;
    border-right: 1px dashed #d9d9d9;
    .select-book {
      display: flex;
      align-items: center;
      justify-content: space-between;
      > p {
        width: 360px;
      }
    }
  }
  .form-body-left,
  .form-body-right {
    padding-top: 10px;
    width: 800px;
  }
  .form-body-right {
    padding-left: 10px;
    position: relative;
    .insert-button {
      position: absolute;
      top: 52px;
      right: 10px;
      z-index: 10002;
    }
  }
}

// 手机样式
.device-ios {
  width: 375px;
  height: 660px;
  margin: 0 auto;
  background: #111;
  border-radius: 55px;
  box-shadow: 0px 0px 0px 2px #aaa;
  padding: 60px 10px;
  position: relative;
  margin-right: 80px;
}

.device-ios:before {
  content: '';
  width: 60px;
  height: 10px;
  border-radius: 10px;
  position: absolute;
  left: 50%;
  margin-left: -30px;
  background: #333;
  top: 30px;
}

.device-ios:after {
  content: '';
  position: absolute;
  width: 60px;
  height: 60px;
  left: 50%;
  margin-left: -30px;
  bottom: 0px;
  border-radius: 100%;
  box-sizing: border-box;
  border: 5px solid #333;
}

.device-inner {
  background-color: #fff;
  height: 100%;
  overflow-y: auto;
}

/deep/ .necessary > .el-form-item__label {
  position: relative;
}
/deep/ .necessary > .el-form-item__label::before {
  position: absolute;
  top: 0;
  left: -8px;
  content: '*';
  color: #f56c6c;
  margin-right: 4px;
}
/deep/ .el-table th.is-leaf {
  border-bottom: none !important;
}
/deep/ .editResource .el-dialog__body {
  padding-top: 0 !important;
  padding-bottom: 10px !important;
}
#wangEditor img {
  max-width: 100% !important;
}
/deep/ #deviceInner * {
  max-width: 340px !important;
  height: auto !important;
}

iframe {
  width: 100%;
  height: 800px;
  margin: 0 auto;
}
.iframe-dialog {
  // position: relative;
  /deep/ .el-dialog__header {
    padding-bottom: 30px !important;
    text-align: center !important;
    .el-dialog__title {
      font-weight: bold;
      font-size: 20px !important;
    }
  }
  /deep/ .el-dialog__body {
    padding-top: 0 !important;
  }
  .loading-div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #969696;
    font-size: 16px;
  }
}
.login-box {
  margin: 0 auto;
}

.code-img-box {
  width: 320px;
}

.code-img-box img {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}
.mask {
  width: 50px;
  height: 50px;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 99999;
  transform: translate(-50%, -50%);
  /deep/ .el-loading-mask {
    background: transparent !important;
  }
}
/**
*end by devT
**/
</style>
